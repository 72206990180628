import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Header from './components/Header';
import RouterConfig from './navigation/RouterConfig';

import FOOTER from './config/footer.json';
import HEADER from './config/header.json';

const useStyles = makeStyles((theme: Theme) => ({
  layout__main: {},
  stuffing: {
    height: HEADER.HEIGHT.MOBILE,
    [theme.breakpoints.up('md')]: {
      height: HEADER.HEIGHT.DESKTOP,
    },
  },
  footer: {
    height: FOOTER.HEIGHT.MOBILE,
    background: theme.palette.background.paper,
  },
}));
const App: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      overflow="hidden"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box width="100vw" position="fixed" top={0} left={0} zIndex={1000}>
        <Header />
      </Box>
      <Box className={classes.stuffing} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <RouterConfig />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.footer}
        px={2}
      >
        <Typography>
          São Francisco, Niterói / RJ - (21) 99269-8475 | © Copyright 2020
        </Typography>
      </Box>
    </Box>
  );
};

export default App;
