import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

import { Link } from 'react-router-dom';

import Container1 from '../assets/images/Container_01.png';
import Container2 from '../assets/images/Container_02.png';
import Container3 from '../assets/images/Container_03.png';
import Container4 from '../assets/images/Container_04.png';

import FruitsImage from '../assets/images/feira.jpg';

import Reefer20 from '../assets/images/MedidasReefer20.png';
import Reefer40 from '../assets/images/MedidasReefer40.png';

import CardMediaModal from '../components/CardMediaModal';

import FOOTER from '../config/footer.json';
import HEADER from '../config/header.json';

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    height: 150,
  },
  card: {
    zIndex: 100,
    height: '100%',
  },
  item1: { order: 2, [theme.breakpoints.up('md')]: { order: 1 } },
  item2: { order: 1, [theme.breakpoints.up('md')]: { order: 2 } },
  item3: { order: 3 },
  card_content: { height: '100%' },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal_box: { maxWidth: '90vw' },
  image_overlay: {
    height: '100%',
    width: '100%',
    background: 'black',
    opacity: 0.7,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 6,
  },
  wrapper: {
    minHeight: `calc(100vh - ${HEADER.HEIGHT.MOBILE + FOOTER.HEIGHT.MOBILE}px)`,
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - ${
        HEADER.HEIGHT.DESKTOP + FOOTER.HEIGHT.DESKTOP
      }px)`,
    },
  },
}));

const reeferImages = { 1: Reefer20, 2: Reefer40 };

const Containers: React.FC = () => {
  const classes = useStyles();

  const [reefer, setReefer] = useState('');
  const [reeferModalOpen, setReeferModalOpen] = useState(false);

  const openReeferModal = (id: keyof typeof reeferImages) => {
    setReefer(reeferImages[id]);
    setReeferModalOpen(true);
  };

  const handleModelClose = () => {
    setReeferModalOpen(false);
  };

  return (
    <Box
      position="relative"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100vw"
        height="100%"
      >
        <Box className={classes.image_overlay} />
        <img
          src={FruitsImage}
          alt="bgimage"
          height="100%"
          width="100%"
          style={{ zIndex: 1 }}
        />
      </Box>
      <Container maxWidth="lg" style={{ zIndex: 2, position: 'relative' }}>
        <Box py={5}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={7} md={3} className={classes.item1}>
              <Card className={classes.card}>
                <CardMediaModal
                  className={classes.media}
                  thumbnail={Container1}
                  imageOnPreview={Container2}
                  title="Container 1"
                />
                <CardContent>
                  <Typography>
                    Dispõe de um sistema de refrigeração (compressor,
                    condensador, entrada de ar, controlador externo e
                    instrumento de expansão e evaporação) utilizando corrente
                    elétrica trifásica 220v, 380v e 440v.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={6} className={classes.item2}>
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      style={{ color: 'yellow' }}
                    >
                      Containers Reefers ou Refrigerados
                    </Typography>
                    <Box pt={5} />
                    <Typography align="center">
                      Feito de estrutura em liga de aço galvanizado e
                      duralumínio, possui isolamento térmico de poliuretano e
                      acabamento em inox nas paredes internas. Projetado para
                      fornecer ar resfriado pelo chão, de baixo para cima,
                      através de decks em forma de “T”, possibilitando um fluxo
                      consistente e uniforme de ar distribuído por toda área
                      interna do container de maneira equilibrada.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      pt={2}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Button
                            style={{
                              width: '100%',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => openReeferModal(1)}
                          >
                            20' (6m)
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            style={{
                              width: '100%',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => openReeferModal(2)}
                          >
                            40' (12m)
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={3} className={classes.item3}>
              <Card className={classes.card}>
                <CardMediaModal
                  className={classes.media}
                  thumbnail={Container3}
                  imageOnPreview={Container4}
                  title="Container 1"
                />
                <CardContent>
                  <Typography>
                    Mobilidade e rapidez em ampliar os espaços
                    refrigerados/resfriados, substituindo caminhões/câmaras
                    frigoríficas, facilitando a logística nas suas
                    sazonalidades.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={reeferModalOpen}
          onClose={handleModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <Fade in={reeferModalOpen}>
            <Box maxWidth="100%">
              <img
                className={classes.modal_box}
                src={reefer}
                alt="container_image"
              />
            </Box>
          </Fade>
        </Modal>
      </Container>
    </Box>
  );
};

export default Containers;
