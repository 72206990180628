import { Toolbar, Button, Box, Popover, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const AMVToolbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Toolbar>
      <Button
        component={NavLink}
        activeStyle={{ color: '#7c90f3' }}
        to="/"
        exact
      >
        Início
      </Button>
      <Button aria-describedby={id} onClick={handleClick}>
        Quem somos
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2} maxWidth={500}>
          <Typography>
            A AM&V BRASIL Containers e Locações foi fundada em 2007, após anos
            de experiências de seus fundadores em diversas áreas do Transporte
            Marítimo como, Terminais, Armadores, Agencias Marítimas e Cia
            Leasings. Nosso foco é atender e prestar serviços por excelência,
            fornecendo containers refrigerados/ frigoríficos aos clientes que
            necessitam armazenar seus produtos perecíveis com temperaturas
            controladas.
          </Typography>
        </Box>
      </Popover>
      <Button
        component={NavLink}
        activeStyle={{ color: '#7c90f3' }}
        to="/containers"
      >
        Equipamentos
      </Button>
      <Box pl={2}>
        <Button
          component={NavLink}
          activeStyle={{ color: '#7c90f3' }}
          to="/contact"
        >
          Contato
        </Button>
      </Box>
    </Toolbar>
  );
};

export default AMVToolbar;
