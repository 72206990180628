import { Route, Switch } from 'react-router-dom';
import Contact from '../pages/Contact';
import Containers from '../pages/Containers';
import Home from '../pages/Home';

const RouterConfig: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/about">About</Route>
      <Route path="/containers">
        <Containers />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
    </Switch>
  );
};

export default RouterConfig;
