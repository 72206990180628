import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const breakpoints = createBreakpoints({});
const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: { '&:visited': { color: 'inherit' } },
        span: { '&:visited': { color: 'inherit' } },
      },
    },
  },
  palette: { type: 'dark', primary: { main: '#7c90f3' } },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 800,
      [breakpoints.down('sm')]: {
        fontSize: '2.125rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontSize: '2.125rem',
      fontWeight: 500,
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
      // [breakpoints.down('xs')]: {
      //     fontSize: '1rem',
      // },
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      // [breakpoints.down('xs')]: {
      //     fontSize: '0.875rem',
      // },
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    body1: {
      fontSize: '1rem',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 300,
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  },
});

const AppWrapper: React.FC = () => {
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <App />
        </Router>
      </ThemeProvider>
    </Box>
  );
};

export default AppWrapper;
