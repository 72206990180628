import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import ContainerImg from '../assets/images/PatioReefer.jpg';
import { useMediaQuery } from '@material-ui/core';

import FOOTER from '../config/footer.json';
import HEADER from '../config/header.json';

const useStyles = makeStyles((theme: Theme) => ({
  landing_image: {
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
  },
  landing_image_container: {
    height: '100%',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
  },
  image_overlay: {
    height: '100%',
    width: '100%',
    background: 'black',
    opacity: 0.7,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 6,
  },
  hero: {
    minHeight: `calc(100vh - ${HEADER.HEIGHT.MOBILE + FOOTER.HEIGHT.MOBILE}px)`,
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - ${
        HEADER.HEIGHT.DESKTOP + FOOTER.HEIGHT.DESKTOP
      }px)`,
    },
  },
  media: {
    height: 140,
    '& img': { objectFit: 'cover', height: '100%', width: '100%' },
  },
  card: { height: '100%' },
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const desktopBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Box
        className={classes.hero}
        maxWidth="100vw"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="100vw"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          zIndex={6}
          px={1}
        >
          <Typography variant="h1" style={{ color: 'yellow' }}>
            Containers Refrigerados
          </Typography>
          <Box pt={2} />
          <Typography variant="h2" align="center">
            Solução inteligente para conservação e transportes de seus produtos
          </Typography>
          <Box pt={5} />
          <Typography variant="h4" align="center">
            Armazene carnes, frutas, laticínios, gelos, produtos farmacêuticos e
            diversos
            {desktopBreakpoint && <br />} que necessitam de controle de
            temperatura entre -25° e +25°C
          </Typography>
        </Box>
        <Box className={classes.landing_image_container}>
          <Box className={classes.image_overlay} />
          <img
            src={ContainerImg}
            alt="imagem_container"
            className={classes.landing_image}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
