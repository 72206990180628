import {
  Backdrop,
  Box,
  CardMedia,
  Fade,
  makeStyles,
  Modal,
  Popover,
  Typography,
} from '@material-ui/core';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { useState } from 'react';

interface CardMediaModalProps {
  thumbnail: string;
  imageOnPreview: string;
  title: string;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal_box: { maxWidth: '90vw' },
}));

const CardMediaModal: React.FC<CardMediaModalProps> = ({
  thumbnail,
  imageOnPreview,
  title,
  className,
}) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  const [overlayOpen, setOverlayOpen] = useState(false);

  const handleOverlayOpen = () => setOverlayOpen(true);

  const handleOverlayClose = () => setOverlayOpen(false);

  const handleModelClose = () => setModalOpen(false);

  return (
    <Box position="relative">
      <CardMedia
        image={thumbnail}
        title={title}
        className={className}
        onMouseEnter={handleOverlayOpen}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      <Fade in={overlayOpen}>
        <Box
          className={classes.overlay}
          onMouseLeave={handleOverlayClose}
          onClick={() => {
            setOverlayOpen(false);
            setModalOpen(true);
          }}
        >
          <AspectRatioIcon />
          <Box pl={1} />
          <Typography>Expandir</Typography>
        </Box>
      </Fade>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleModelClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={modalOpen}>
          <Box maxWidth="100%">
            <img
              className={classes.modal_box}
              src={imageOnPreview}
              alt="container_image"
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default CardMediaModal;
