import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';
import StorageIcon from '@material-ui/icons/Storage';
import AMVToolbar from './AMVToolbar';

import HEADER from '../config/header.json';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    transition: 'height 250ms',
    background: theme.palette.background.paper,
    height: HEADER.HEIGHT.MOBILE,
    boxShadow: '1px 1px 1px black',
    [theme.breakpoints.up('md')]: { height: HEADER.HEIGHT.DESKTOP },
  },
  container: { height: '100%' },
  button: {
    '& span': { color: 'inherit !important' },
  },
  span: {
    color: theme.palette.primary.main,
  },
  selected_link: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.main,
  },
}));

const LIST_ITEMS = [
  { text: 'Início', to: '/', Icon: HomeIcon },
  { text: 'Equipamentos', to: '/containers', Icon: StorageIcon },
  { text: 'Contato', to: '/contact', Icon: MailIcon },
];

const Header: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const desktopBreakpoint = useMediaQuery(
    `${theme.breakpoints.up('md')} and (orientation: landscape)`
  );

  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => setDrawerOpen(false), [desktopBreakpoint]);

  return (
    <>
      <Box className={classes.header}>
        <Container maxWidth="xl" className={classes.container}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection={mobileBreakpoint ? 'column' : 'row'}
              alignItems={mobileBreakpoint ? 'flex-start' : 'flex-end'}
              onClick={() => history.push('/')}
              style={{ cursor: 'pointer' }}
            >
              <Box>
                <Typography variant="h2">
                  <span className={classes.span}>AM&V Brasil</span>
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                height="100%"
                pl={mobileBreakpoint ? 0 : 2}
                pb={0.5}
              >
                <Typography variant="h3">Containers e Locações</Typography>
              </Box>
            </Box>
            {desktopBreakpoint ? (
              <AMVToolbar />
            ) : (
              <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Container>
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <Box width={268}>
          <List>
            {LIST_ITEMS.map(({ Icon, text, to }, i) => {
              return (
                <ListItem
                  key={i}
                  button
                  component={Link}
                  to={to}
                  onClick={() => setDrawerOpen(false)}
                >
                  <Box pl={2} />
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
            <Box pt={1}>
              <Divider />
            </Box>
            <ListItem button onClick={() => setDialogOpen(true)}>
              <Box pl={2} />
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={'Quem Somos'} />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Quem Somos?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A AM&V BRASIL Containers e Locações foi fundada em 2007, após anos
            de experiências de seus fundadores em diversas áreas do Transporte
            Marítimo como, Terminais, Armadores, Agencias Marítimas e Cia
            Leasings. Nosso foco é atender e prestar serviços por excelência,
            fornecendo containers refrigerados/ frigoríficos aos clientes que
            necessitam armazenar seus produtos perecíveis com temperaturas
            controladas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
