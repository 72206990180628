import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { api } from '../api';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px 0',
  },
}));

const Contact: React.FC = () => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [telephone, setTelephone] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handlerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      setLoading(true);

      const res = await api.post('/mail.php', {
        name,
        email,
        telephone,
        message,
      });

      setLoading(false);

      if (res.status !== 200) {
        setError(true);
      } else {
        setError(false);
        setName('');
        setMessage('');
        setEmail('');
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="md">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          error
            ? 'Algo deu errado. Tente novamente'
            : 'Email enviado com sucesso'
        }
      />
      <Box py={5} display="flex" alignItems="center" justifyContent="center">
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper>
              <Box pt={6} px={4} pb={4}>
                <Typography variant="h4" align="center">
                  Fale Conosco
                </Typography>
                <Box pt={6} />
                <form autoComplete="on" onSubmit={handlerSubmit}>
                  <TextField
                    id="standard-basic"
                    label="Nome Completo"
                    variant="outlined"
                    color="primary"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    fullWidth
                  />
                  <Box pt={2} />
                  <TextField
                    id="standard-basic"
                    label="Número de contato"
                    variant="outlined"
                    color="primary"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    required
                    fullWidth
                  />
                  <Box pt={2} />
                  <TextField
                    id="filled-basic"
                    label="Email"
                    type="email"
                    variant="outlined"
                    color="primary"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                  />
                  <Box pt={2} />
                  <TextField
                    id="outlined-basic"
                    multiline
                    label="Sua mensagem"
                    variant="outlined"
                    color="primary"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    minRows="10"
                    maxRows="10"
                  />
                  <Box display="flex" pt={4}>
                    {loading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        style={{ width: '100%' }}
                        variant="outlined"
                        color="primary"
                        type="submit"
                      >
                        Enviar
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Paper>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            md={5}
            spacing={2}
          >
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography align="center" variant="h4">
                  Email
                </Typography>
                <Box pb={1} />
                <Typography align="center">amv@amvcontainers.com.br</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography align="center" variant="h4">
                  Facebook
                </Typography>
                <Box pb={1} />
                <Link
                  color="primary"
                  component="a"
                  href="https://facebook.com/amvcontainers"
                  align="center"
                >
                  @amvcontainers
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography align="center" variant="h4">
                  Instagram
                </Typography>
                <Box pb={1} />
                <Link
                  color="primary"
                  component="a"
                  href="https://instagram.com/amvcontainers"
                  align="center"
                >
                  @amvcontainers
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography align="center" variant="h4">
                  Telefones
                </Typography>
                <Box pb={1} />
                <Typography align="center">(21) 99269-8475</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Contact;
